* {
    box-sizing: border-box;
}

body {
    margin: 0;
    background-color: #333;
}

.nav {
    background-color: #333;
    color: white;
    display: flex;
    justify-content: space-between;
    /* Positions items at opposite ends of the container */
    height: 5rem;
    align-items: center;
    /* Adjust if you want the items to stretch vertically */
    gap: 2rem;
    padding: 0rem 3rem 3rem 3rem;
    /* This background color will be overridden by the previous one */
    font-family: Arial, sans-serif;
    margin-top: 3rem;
}

.site-title {
    font-size: 3rem;
    width: 400px;
}

.nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 1rem;
    font-size: 1.5rem;
}

.nav a {
    height: 100%;
    display: flex;
    align-items: center;
    color: inherit;
    text-decoration: none;
}

.nav li.active {
    background-color: #555;
}

.nav li:hover {
    background-color: #777;
}


.container {
    /* Set a minimum margin for the container */
    padding-top: 50px;
    margin: 20px auto;
    /* Auto margin horizontally centers the container */
    max-width: 800px;

    /* Set a fixed width for the container */
    .container {
        /* Set a minimum margin for the container */
        margin: 20px auto;
        /* Auto margin horizontally centers the container */
        max-width: 800px;
        /* Set a fixed width for the container */
        font-family: Arial, sans-serif;
    }

}

@media (max-width: 850px) {
    .container {
        max-width: 600px;
        /* Reduce the width of the container to 80% of the window width */
    }
}


@media (max-width: 650px) {
    .container {
        max-width: 400px;
    }
}

.mainBody {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    flex-direction: column;
}

.textBody {

    padding-bottom: 2rem;
    font-size: larger;
}


.form {
    display: flex;
    flex-direction: column;
}

.form-inputs {
    width: 100%;
    padding-right: 15px;
    padding-bottom: 15px;
}

.form-inputs p {
    color: red;
}

.name-forms {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.name-input {
    display: block;
    padding-left: 10px;
    outline: none;
    border-radius: 10px;
    height: 40px;
    width: 100%;
    border: solid;
    border-color: white;
    border-width: thin;
    background-color: #333;
}

.email-input {
    display: block;
    padding-left: 10px;
    outline: none;
    border-radius: 10px;
    height: 40px;
    width: 100%;
    border: solid;
    border-color: white;
    border-width: thin;
    background-color: #333;

}



.form-content-right {
    width: 100%;


}

.form-input-btn {
    width: 40%;
    height: 50px;
    margin-top: 10px;
    border-radius: 10px;
    outline: none;
    border: none;
    color: white;
    font-size: 1rem;
}

.button_text {
    color: #333;
}

.success_message {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 50px;
}


/* Add basic styling for the buttons */
.social-buttons {
    text-align: center;
    margin-top: 100px;
}

.twitter-button,
.linkedin-button {
    display: inline-block;
    padding: 10px 20px;
    margin: 5px;
    background-color: #1DA1F2;
    /* Twitter Blue */
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-size: 16px;
}

.linkedin-button {
    background-color: #0077B5;
    /* LinkedIn Blue */
}

.arrow-icon {
    margin: 0 5px;
    /* Add space between the arrow and the words */
    display: inline-flex;
    /* This allows us to vertically align the icon */
    align-items: center;
    /* Vertically align the icon with text */
}


.arrow-icon .icon {
    font-size: 20px;
    /* Adjust this value to resize the icon */
    vertical-align: middle;
    /* Shift the icon down to align it with the middle of the line */
}
.vertical{
    font-size: 20px;
    font-weight: bold;
}
/* Add the bullet point style and align the text after the bullet */
.bullet-points p::before {
    content: "\2022"; /* Unicode bullet point (•) */
    display: inline-block;
    width: 10px; /* Adjust the width of the space between the bullet and the text */
    text-align: center;
    margin-right: 5px; /* Adjust the spacing between the bullet and the text */
  }
  
  /* Align the text after the bullet point */
  .bullet-points p {
    margin-left: 25px;
    text-indent: -15px; /* Adjust the indentation value as needed */
  }


 .smallHeading {
    margin-top: 40px;
 }